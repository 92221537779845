import React, { useState } from "react"
import classNames from "classnames"
import Helmet from "react-helmet"

import style from "./index.module.css"
import { Link } from "gatsby"

const BurgerMenu: React.FunctionComponent = props => {
  const [isBurgerOpen, setBurgerMenuOpen] = useState(false)

  const toggleBurgerMenu = (): void => {
    setBurgerMenuOpen(!isBurgerOpen)
  }

  return (
    <>
      <Helmet>
        <body className={classNames({ [style.openBody]: isBurgerOpen })} />
      </Helmet>
      <div
        className={classNames({ [style.open]: isBurgerOpen }, style.burgerNav)}
        onClick={toggleBurgerMenu}
      >
        <ul>
          <li>
            Services
            <ul>
              <li>
                <Link className={style.burgerLink} to="/services/residential">
                  Residential
                </Link>
              </li>
              <li>
                <Link className={style.burgerLink} to="/services/commercial">
                  Commercial
                </Link>
              </li>
            </ul>
          </li>

          <li>
            Portfolio
            <ul>
              <li>
                <Link className={style.burgerLink} to="/portfolio/bayview">
                  Bayview
                </Link>
              </li>
              <li>
                <Link
                  className={style.burgerLink}
                  to="/portfolio/elizabeth-bay"
                >
                  Elizabeth Bay
                </Link>
              </li>

              <li>
                <Link className={style.burgerLink} to="/portfolio/mosman">
                  Mosman
                </Link>
              </li>

              <li>
                <Link
                  className={style.burgerLink}
                  to="/portfolio/northern-beaches"
                >
                  Northen Beaches
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link className={style.burgerLink} to="/about">
              About
            </Link>
          </li>
          <li>
            <Link className={style.burgerLink} to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div
        className={classNames(
          { [style.open]: isBurgerOpen },
          style.burgerContainer
        )}
        onClick={toggleBurgerMenu}
      >
        <div
          className={classNames(
            { [style.open]: isBurgerOpen },
            style.burgerMenu
          )}
          onClick={toggleBurgerMenu}
        >
          <div className={style.burgerBuntop}></div>
          <div className={style.burgerBunmid}></div>
          <div className={style.burgerBunbottom}></div>
        </div>
      </div>
    </>
  )
}

export default BurgerMenu
