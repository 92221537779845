import React from "react"
import { Menu, MenuList, MenuButton, MenuLink } from "@reach/menu-button"
import "@reach/menu-button/styles.css"
import { Link } from "gatsby"

import BurgerMenu from "../burger-menu"

import style from "./index.module.css"
import logo from "./logo_andvic.png"

export interface LayoutProps {
  children: React.ReactNode
}

const AndvicHeader: React.FunctionComponent = () => {
  return (
    <header>
      <nav className={style.nav}>
        <div className={style.navInner}>
          <ol className={style.navItemsList}>
            <li>
              <Menu>
                <MenuButton>SERVICES</MenuButton>
                <MenuList className={style.menuList}>
                  <MenuLink
                    className={style.menuLink}
                    as={Link}
                    to="/services/residential"
                  >
                    RESIDENTIAL
                  </MenuLink>

                  <MenuLink
                    className={style.menuLink}
                    as={Link}
                    to="/services/commercial"
                  >
                    COMMERCIAL
                  </MenuLink>
                </MenuList>
              </Menu>
            </li>
            <li>
              <Link to="/portfolio"></Link>

              <Menu>
                <MenuButton>PORTFOLIO</MenuButton>
                <MenuList className={style.menuList}>
                  <MenuLink
                    className={style.menuLink}
                    as={Link}
                    to="/portfolio/bayview"
                  >
                    Bayview
                  </MenuLink>
                  <MenuLink
                    className={style.menuLink}
                    as={Link}
                    to="/portfolio/elizabeth-bay"
                  >
                    Elizabeth Bay
                  </MenuLink>
                  <MenuLink
                    className={style.menuLink}
                    as={Link}
                    to="/portfolio/mosman"
                  >
                    Mosman
                  </MenuLink>
                  <MenuLink
                    className={style.menuLink}
                    as={Link}
                    to="/portfolio/northern-beaches"
                  >
                    Northern Beaches
                  </MenuLink>
                </MenuList>
              </Menu>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ol>

          <Link to="/">
            <img className={style.logo} src={logo}></img>
          </Link>
        </div>
      </nav>
    </header>
  )
}

export default AndvicHeader
